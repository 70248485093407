
import { Component, Watch } from "vue-property-decorator";
import UpdateMixin from "@/mixins/Crud/UpdateMixin";
import MachineForm from "@/views/BaseData/Machine/MachineForm.vue";
import { Machine } from "@/config/Modules";
import { mixins } from "vue-class-component";
import { MachineStoreMixin } from "@/mixins/Stores//MachineStoreMixin.ts";
import { ICrudOptions } from "@/interfaces/ICrudOptions";
import ImageViewAndUpload from "@/components/media/ImageViewAndUpload.vue";
import IMedia from "@/interfaces/IMedia";
import IMachine from "@/interfaces/IMachine";

@Component({
  components: { ImageViewAndUpload, MachineForm },
  mixins: [UpdateMixin],
})
export default class MachineUpdate extends mixins(MachineStoreMixin) {
  protected init(): void {
    this.findOneMachineItemAction({
      id: this.$route.params.id,
      resource: this.resource,
    });
  }

  public get resource(): string {
    return Machine.resource;
  }

  public get descriptionField(): string {
    return Machine.description_field;
  }

  public updateItemAction(options: ICrudOptions): void {
    this.updateMachineItemAction(options);
  }
}
